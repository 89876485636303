<template>
  <div>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js'
import md5 from 'js-md5'
import { getUrlParam } from '@/util/public'
import { getCasUserInfo } from '@/api/public'
export default {
  name: 'casLogin',
  data () {
    return { }
  },
  created () {
    const status = getUrlParam('status')
    if (!status || status === '0') {
      this.showDialogOut('用户在选寝系统中不存在')
      return false
    }
    // uid
    const uid = decodeURIComponent(getUrlParam('uid'))
    // 用户名
    const cn = decodeURIComponent(getUrlParam('cn'))
    // 密文
    const sign = decodeURIComponent(getUrlParam('sign'))
    // 密钥
    const key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CAS_KEY)
    // 密文
    const word = CryptoJS.enc.Utf8.parse('uid=' + uid + '&cn=' + cn)
    // 一次加密
    const encrypted = CryptoJS.AES.encrypt(word, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
    // 二次加密
    const temp = md5(encrypted.toString())
    if (sign === temp) {
      // uid存入缓存
      window.localStorage.setItem('openid', uid)
      getCasUserInfo({ uid: window.localStorage.getItem('openid') }).then(res => {
        const { data: r } = res
        const { code, data, msg } = r
        localStorage.setItem('userInfo', JSON.stringify(data))
        localStorage.setItem('token', window.localStorage.getItem('openid'))
        if (code === 0) {
          this.to()
        } else {
          this.showDialogOut(msg)
        }
        this.loading = false
      }).catch(err => { console.log(err) })
      return false
    } else {
      this.showDialogOut('用户信息错误')
    }
  },
  methods: {
    to () {
      const userInfo = window.localStorage.getItem('userInfo')
      let { status, bedId } = JSON.parse(userInfo)
      status = Number(status)
      if (status === -1) {
        // this.$toast.fail('输入教工号或学号及姓名绑定账号')
      }
      // 学生
      if (status === 1) {
        if (bedId === null) {
          this.$router.replace('/dormitory/areaChoose')
        } else {
          this.$router.replace('/dormitory/checkInfo')
        }
      }
      // 教师
      if (status === 2) {
        this.$router.replace('/dormitory/totalStatus')
      }
    },
    // 退出
    showDialogOut (title) {
      this.$dialog.alert({
        title: '' + title,
        confirmButtonColor: '#1677ff',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            done()
            location.replace(process.env.VUE_APP_CAS_OUT)
          }
        }
      }).then().catch(() => {})
    }
  }
}
</script>

<style scoped>

</style>
